var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"painting-station-wrapper pt-2"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column pt-0 wipin-list"},[_c('h2',{staticClass:"title bottom-border is-5 mb-2 pb-1 has-text-centered"},[_vm._v(" WIP-in "+_vm._s(_vm.faci.params.kanbanCapa ? ("(" + (_vm.wipProducts.all.length) + "/" + (_vm.faci.params.kanbanCapa) + ")") : ("(" + (_vm.wipProducts.all.length) + ")"))+" ")]),_c('div',{staticClass:"max-height-400 bottom-border"},[(!_vm.wipProducts.all.length)?_c('div',{staticClass:"is-italic has-text-centered my-4 px-3 has-text-grey"},[_vm._v(" You are waiting to receive products from the preceding station ")]):_vm._e(),_c('stock-list',{ref:"wipstocklist",attrs:{"faci":_vm.faci,"zone":"wipin","is-disab":_vm.isDisab},model:{value:(_vm.wipProducts),callback:function ($$v) {_vm.wipProducts=$$v},expression:"wipProducts"}})],1)]),_c('div',{staticClass:"column is-5",class:{
        'bg-is-flex-column': !_vm.workdeskProduct,
        'pb-6': !_vm.workdeskProduct
      }},[_c('div',{staticClass:"columns is-centered desk-product-wrapper pb-1 is-clipped"},[_c('div',{staticClass:"column is-10 is-relative"},[_c('transition',{attrs:{"name":"fade"}},[(!_vm.workdeskProduct)?_c('div',{staticClass:"tag-center has-text-centered is-italic is-size-5 has-text-grey"},[_vm._v(" Empty workdesk ")]):_vm._e()]),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.workdeskProduct)?_c('div',{class:{ 'is-left': !_vm.isSlideRight },style:(("transition: all " + (_vm.faci.params.loadingDuration ||
                  300) + "ms ease-in-out;"))},[(_vm.isChangeover)?_c('div',{staticClass:"tag-center"},[_c('p',{staticClass:"has-text-centered is-size-4 has-text-white"},[_vm._v(" Changing tool "),_c('span',{staticClass:"is-size-5"},[_vm._v(" ("+_vm._s(_vm.faci.params.changeoverDuration / 1000)+"s) ")])]),_c('b-progress',{attrs:{"type":"is-info","size":"is-large","value":_vm.changeoverAnim.pourc,"min":0,"max":100}})],1):_vm._e(),_c('p',{staticClass:"has-text-centered mb-2"},[_c('em',[_vm._v(_vm._s(_vm.workdeskProduct.name))]),_vm._v(" - "),_c('b',[_vm._v("#"+_vm._s(_vm.workdeskProduct.rankid))])]),(_vm.workdeskProduct && _vm.workdeskProduct.type)?_c(_vm.leanProductFigures[_vm.workdeskProduct.type],{tag:"component",staticClass:"workdesk-figure",attrs:{"product":_vm.workdeskProduct,"station":_vm.faci,"faciParams":_vm.localParams,"show-guides":_vm.workdeskTemplate && _vm.workdeskTemplate.showGuides,"show-finished":false,"is-disabled":_vm.isChangeover || _vm.isDisab},on:{"applyopestatus":_vm.applyStatus,"addtrace":_vm.addTrace,"removetrace":_vm.removeTrace}}):_vm._e()],1):_vm._e()])],1)]),_c('transition',{attrs:{"name":"fade"}},[(_vm.workdeskProduct)?_c('div',{style:(("transition: all " + (_vm.faci.params.loadingDuration ||
              300) + "ms ease-in-out;"))},[(_vm.localParams.color && _vm.localParams.shape)?_c('div',{staticClass:"has-text-centered is-italic"},[_vm._v(" Click to paint dots at the right location. ")]):_vm._e(),(_vm.faci.params.stampRemove)?_c('div',{staticClass:"has-text-centered is-italic"},[_vm._v(" Click on the dots wrongly painted to remove them. ")]):_vm._e(),(
              _vm.localParams.color &&
                _vm.faci.params.availColors &&
                _vm.faci.params.availColors.length
            )?_c('b-field',{staticClass:"my-1"},[_c('b-field',_vm._l((_vm.faci.params.availColors),function(col){return _c('b-radio-button',{key:col,attrs:{"expanded":"","native-value":col,"type":_vm.leanParams.availColors[col].class,"disabled":_vm.isChangeover || _vm.isDisab},on:{"input":_vm.changeTool},model:{value:(_vm.localParams.color),callback:function ($$v) {_vm.$set(_vm.localParams, "color", $$v)},expression:"localParams.color"}},[_c('b-icon',{style:({ color: _vm.leanParams.availColors[col].colorHex }),attrs:{"icon":"radiobox-marked"}}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm._f("capitalize")(_vm.leanParams.availColors[col].colorName)))])],1)}),1)],1):_vm._e(),(
              _vm.localParams.shape &&
                _vm.faci.params.availShapes &&
                _vm.faci.params.availShapes.length
            )?_c('b-field',{staticClass:"my-1"},[_c('b-field',_vm._l((_vm.faci.params.availShapes),function(sha){return _c('b-radio-button',{key:sha,attrs:{"expanded":"","native-value":sha,"type":"is-info is-light","disabled":_vm.isChangeover || _vm.isDisab},on:{"input":_vm.changeTool},model:{value:(_vm.localParams.shape),callback:function ($$v) {_vm.$set(_vm.localParams, "shape", $$v)},expression:"localParams.shape"}},[_c('b-icon',{attrs:{"icon":_vm.leanParams.availShapes[sha].icon}}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm._f("capitalize")(_vm.leanParams.availShapes[sha].shape)))])],1)}),1)],1):_vm._e(),_c('div',{staticClass:"mt-3"},[_c('b-button',{attrs:{"type":"is-warning","size":"is-medium","rounded":"","disabled":_vm.isDisab || !_vm.checkEnoughSpace['wipin'],"icon-left":"arrow-left"},on:{"click":function($event){return _vm.moveToZone(_vm.workdeskProduct, 'wipin')}}},[_vm._v("Back to WIP")]),_c('b-button',{staticClass:"is-pulled-right",attrs:{"type":"is-primary","size":"is-medium","disabled":_vm.isDisab || !_vm.checkEnoughSpace['wipout'],"rounded":"","icon-right":"arrow-right"},on:{"click":function($event){return _vm.moveToZone(_vm.workdeskProduct, 'wipout')}}},[_vm._v("Done")])],1)],1):_vm._e()]),_c('transition',{attrs:{"name":"fade"}},[(!_vm.workdeskProduct)?_c('div',{staticClass:"mt-auto",style:(("transition: all " + (_vm.faci.params.loadingDuration ||
              300) + "ms ease-in-out;"))},[_c('div',{staticClass:"buttons is-centered mt-4"},[(!_vm.doneProducts.selected.length)?_c('b-button',{attrs:{"id":"loadnext-btn","size":"is-medium","type":"is-primary","icon-left":"arrow-right","rounded":"","disabled":_vm.isDisab || !_vm.nextProduct},on:{"click":function($event){return _vm.moveToZone(_vm.nextProduct, 'workdesk')}}},[_vm._v("Load next "),(_vm.nextProduct)?_c('span',[_vm._v("- #"+_vm._s(_vm.nextProduct.rankid))]):_vm._e()]):_vm._e(),(_vm.doneProducts.selected.length)?_c('b-button',{attrs:{"size":"is-medium","type":"is-warning","rounded":"","icon-right":"arrow-left","disabled":_vm.isDisab},on:{"click":function($event){return _vm.moveToZone(_vm.doneProducts.selected[0], 'workdesk')}}},[_vm._v("Back to desk - #"+_vm._s(_vm.doneProducts.selected[0].rankid))]):_vm._e()],1)]):_vm._e()])],1),_c('div',{staticClass:"column bg-is-flex-column pt-0"},[_c('h2',{staticClass:"title bottom-border is-5 mb-2 pb-1 has-text-centered"},[_vm._v(" WIP-out "+_vm._s(_vm.faci.params.kanbanCapa ? ("(" + (_vm.doneProducts.all.length) + "/" + (_vm.faci.params.kanbanCapa) + ")") : ("(" + (_vm.doneProducts.all.length) + ")"))+" ")]),_c('div',{staticClass:"max-height-300 bottom-border"},[_c('stock-list',{ref:"donestocklist",attrs:{"faci":_vm.faci,"zone":"wipout","is-disab":_vm.isDisab},model:{value:(_vm.doneProducts),callback:function ($$v) {_vm.doneProducts=$$v},expression:"doneProducts"}})],1),_c('div',{staticClass:"mt-auto pt-2"},[(_vm.doneProducts.all.length)?_c('b',{staticClass:"is-size-6"},[_vm._v("Send to next station"),(
              _vm.faci.prioNextStation &&
                _vm.currentGame.facilities[_vm.faci.prioNextStation]
            )?_c('span',[_vm._v(" ("+_vm._s(_vm.currentGame.facilities[_vm.faci.prioNextStation].name)+")")]):_vm._e(),_vm._v(":")]):_vm._e(),(_vm.faci.params.kanbanCapa)?_c('b-field',{staticClass:"mt-2 is-italic"},[_c('b-checkbox',{attrs:{"type":"is-info","value":_vm.faci.params.sendAuto},on:{"input":function (ev) { return _vm.updateSendAuto(ev); }}},[_vm._v("Move pieces automatically")])],1):_vm._e(),_c('move-stock',{attrs:{"faci":_vm.faci,"is-all":!_vm.doneProducts.selected.length,"all-products":_vm.doneProducts.selected.length
              ? _vm.doneProducts.selected
              : _vm.doneProducts.all,"is-disab":_vm.isDisab,"is-open":true},on:{"movedstock":function($event){return _vm.$refs['donestocklist'].resetSelection()}}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }