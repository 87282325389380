<template>
  <div class="painting-station-wrapper pt-2">
    <div class="columns">
      <div class="column pt-0 wipin-list">
        <h2 class="title bottom-border is-5 mb-2 pb-1 has-text-centered">
          WIP-in
          {{
            faci.params.kanbanCapa
              ? `(${wipProducts.all.length}/${faci.params.kanbanCapa})`
              : `(${wipProducts.all.length})`
          }}
        </h2>
        <div class="max-height-400 bottom-border">
          <div
            v-if="!wipProducts.all.length"
            class="is-italic has-text-centered my-4 px-3 has-text-grey"
          >
            You are waiting to receive products from the preceding station
          </div>
          <stock-list
            :faci="faci"
            zone="wipin"
            v-model="wipProducts"
            ref="wipstocklist"
            :is-disab="isDisab"
          ></stock-list>
        </div>
      </div>
      <div
        class="column is-5"
        :class="{
          'bg-is-flex-column': !workdeskProduct,
          'pb-6': !workdeskProduct
        }"
      >
        <div class="columns is-centered desk-product-wrapper pb-1 is-clipped">
          <div class="column is-10 is-relative">
            <transition name="fade">
              <div
                v-if="!workdeskProduct"
                class="tag-center has-text-centered is-italic is-size-5 has-text-grey"
              >
                Empty workdesk
              </div>
            </transition>
            <transition name="slide-fade">
              <div
                v-if="workdeskProduct"
                :class="{ 'is-left': !isSlideRight }"
                :style="
                  `transition: all ${faci.params.loadingDuration ||
                    300}ms ease-in-out;`
                "
              >
                <div class="tag-center" v-if="isChangeover">
                  <p class="has-text-centered is-size-4 has-text-white">
                    Changing tool
                    <span class="is-size-5">
                      ({{ faci.params.changeoverDuration / 1000 }}s)
                    </span>
                  </p>
                  <b-progress
                    type="is-info"
                    size="is-large"
                    :value="changeoverAnim.pourc"
                    :min="0"
                    :max="100"
                  ></b-progress>
                </div>
                <p class="has-text-centered mb-2">
                  <em>{{ workdeskProduct.name }}</em> -
                  <b>#{{ workdeskProduct.rankid }}</b>
                </p>
                <component
                  class="workdesk-figure"
                  v-if="workdeskProduct && workdeskProduct.type"
                  v-bind:is="leanProductFigures[workdeskProduct.type]"
                  :product="workdeskProduct"
                  :station="faci"
                  :faciParams="localParams"
                  :show-guides="workdeskTemplate && workdeskTemplate.showGuides"
                  :show-finished="false"
                  :is-disabled="isChangeover || isDisab"
                  @applyopestatus="applyStatus"
                  @addtrace="addTrace"
                  @removetrace="removeTrace"
                ></component>
              </div>
            </transition>
          </div>
        </div>
        <transition name="fade">
          <div
            v-if="workdeskProduct"
            :style="
              `transition: all ${faci.params.loadingDuration ||
                300}ms ease-in-out;`
            "
          >
            <div
              class="has-text-centered is-italic"
              v-if="localParams.color && localParams.shape"
            >
              Click to paint dots at the right location.
            </div>
            <div
              class="has-text-centered is-italic"
              v-if="faci.params.stampRemove"
            >
              Click on the dots wrongly painted to remove them.
            </div>
            <b-field
              class="my-1"
              v-if="
                localParams.color &&
                  faci.params.availColors &&
                  faci.params.availColors.length
              "
            >
              <b-field>
                <b-radio-button
                  expanded
                  v-model="localParams.color"
                  v-for="col in faci.params.availColors"
                  :key="col"
                  :native-value="col"
                  @input="changeTool"
                  :type="leanParams.availColors[col].class"
                  :disabled="isChangeover || isDisab"
                >
                  <b-icon
                    icon="radiobox-marked"
                    :style="{ color: leanParams.availColors[col].colorHex }"
                  ></b-icon>
                  <span class="ml-2">{{
                    leanParams.availColors[col].colorName | capitalize
                  }}</span>
                </b-radio-button>
              </b-field>
            </b-field>
            <b-field
              class="my-1"
              v-if="
                localParams.shape &&
                  faci.params.availShapes &&
                  faci.params.availShapes.length
              "
            >
              <b-field>
                <b-radio-button
                  expanded
                  v-model="localParams.shape"
                  v-for="sha in faci.params.availShapes"
                  :key="sha"
                  :native-value="sha"
                  @input="changeTool"
                  type="is-info is-light"
                  :disabled="isChangeover || isDisab"
                >
                  <b-icon :icon="leanParams.availShapes[sha].icon"></b-icon>
                  <span class="ml-2">{{
                    leanParams.availShapes[sha].shape | capitalize
                  }}</span>
                </b-radio-button>
              </b-field>
            </b-field>
            <div class="mt-3">
              <b-button
                type="is-warning"
                size="is-medium"
                @click="moveToZone(workdeskProduct, 'wipin')"
                rounded
                :disabled="isDisab || !checkEnoughSpace['wipin']"
                icon-left="arrow-left"
                >Back to WIP</b-button
              >
              <b-button
                class="is-pulled-right"
                type="is-primary"
                size="is-medium"
                :disabled="isDisab || !checkEnoughSpace['wipout']"
                @click="moveToZone(workdeskProduct, 'wipout')"
                rounded
                icon-right="arrow-right"
                >Done</b-button
              >
            </div>
          </div>
        </transition>
        <transition name="fade">
          <div
            v-if="!workdeskProduct"
            class="mt-auto"
            :style="
              `transition: all ${faci.params.loadingDuration ||
                300}ms ease-in-out;`
            "
          >
            <div class="buttons is-centered mt-4">
              <b-button
                id="loadnext-btn"
                v-if="!doneProducts.selected.length"
                size="is-medium"
                type="is-primary"
                icon-left="arrow-right"
                rounded
                :disabled="isDisab || !nextProduct"
                @click="moveToZone(nextProduct, 'workdesk')"
                >Load next
                <span v-if="nextProduct"
                  >- #{{ nextProduct.rankid }}</span
                ></b-button
              >
              <b-button
                size="is-medium"
                type="is-warning"
                rounded
                icon-right="arrow-left"
                v-if="doneProducts.selected.length"
                :disabled="isDisab"
                @click="moveToZone(doneProducts.selected[0], 'workdesk')"
                >Back to desk - #{{ doneProducts.selected[0].rankid }}</b-button
              >
            </div>
          </div>
        </transition>
      </div>
      <div class="column bg-is-flex-column pt-0">
        <h2 class="title bottom-border is-5 mb-2 pb-1 has-text-centered">
          WIP-out
          {{
            faci.params.kanbanCapa
              ? `(${doneProducts.all.length}/${faci.params.kanbanCapa})`
              : `(${doneProducts.all.length})`
          }}
        </h2>
        <div class="max-height-300 bottom-border">
          <stock-list
            :faci="faci"
            zone="wipout"
            v-model="doneProducts"
            ref="donestocklist"
            :is-disab="isDisab"
          ></stock-list>
        </div>
        <div class="mt-auto pt-2">
          <b class="is-size-6" v-if="doneProducts.all.length"
            >Send to next station<span
              v-if="
                faci.prioNextStation &&
                  currentGame.facilities[faci.prioNextStation]
              "
            >
              ({{ currentGame.facilities[faci.prioNextStation].name }})</span
            >:</b
          >
          <b-field class="mt-2 is-italic" v-if="faci.params.kanbanCapa">
            <b-checkbox
              type="is-info"
              :value="faci.params.sendAuto"
              @input="ev => updateSendAuto(ev)"
              >Move pieces automatically</b-checkbox
            >
          </b-field>
          <move-stock
            :faci="faci"
            :is-all="!doneProducts.selected.length"
            :all-products="
              doneProducts.selected.length
                ? doneProducts.selected
                : doneProducts.all
            "
            :is-disab="isDisab"
            :is-open="true"
            @movedstock="$refs['donestocklist'].resetSelection()"
          ></move-stock>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import anime from "animejs/lib/anime.es.js";
import firebase from "@/firebaseConfig";
import { mapGetters } from "vuex";
import StockList from "@/components/lean/Play/StationElements/StockList.vue";
import MoveStock from "@/components/lean/Play/StationElements/MoveStock.vue";

// import tinycolor from "tinycolor2";

export default {
  name: "PaintingStation",
  components: {
    StockList,
    MoveStock
  },
  props: { faci: Object },
  computed: {
    ...mapGetters([
      "loading",
      "currentUser",
      "currentGame",
      "currentProducts",
      "isCreator",
      "myFaci",
      "leanProductFigures",
      "isLeanGameActive",
      "leanParams"
    ]),
    isDisab() {
      return (
        !this.isLeanGameActive ||
        ((!this.myFaci || this.myFaci.id !== this.faci.id) && !this.isCreator)
      );
    },
    workdeskProduct() {
      return this.currentProducts.find(prod => {
        return prod.pos.fid === this.faci.id && prod.pos.zone === "workdesk";
      });
    },
    keyChange() {
      return (
        "wpid" +
        (this.workdeskProduct ? this.workdeskProduct.id : "") +
        "npid" +
        (this.nextProduct ? this.nextProduct.id : "")
      );
    },
    workdeskTemplate() {
      return this.workdeskProduct
        ? this.currentGame.params.productsTemplates[
            this.workdeskProduct.template_id
          ]
        : null;
    },
    nextProduct() {
      if (this.workdeskProduct || !this.wipProducts.all.length) {
        return null;
      }
      if (this.wipProducts.selected.length) {
        return this.wipProducts.selected[0];
      } else {
        return this.wipProducts.all[0];
      }
    },
    checkEnoughSpace() {
      let countProdWip, countProdDone;
      countProdWip = this.currentProducts.filter(prod => {
        return (
          prod.pos.fid === this.faci.id &&
          prod.pos.zone === "wipin" &&
          !prod.fulfilled_at
        );
      }).length;
      countProdDone = this.currentProducts.filter(prod => {
        return (
          prod.pos.fid === this.faci.id &&
          prod.pos.zone === "wipout" &&
          !prod.fulfilled_at
        );
      }).length;
      return {
        wipin:
          this.faci.params.kanbanCapa &&
          countProdWip >= this.faci.params.kanbanCapa
            ? false
            : true,
        wipout:
          this.faci.params.kanbanCapa &&
          countProdDone >= this.faci.params.kanbanCapa
            ? false
            : true
      };
    }
  },
  created() {
    if (this.faci.params.availColors && this.faci.params.availColors.length) {
      this.localParams.color = this.faci.params.availColors[0];
    }
    if (this.faci.params.availShapes && this.faci.params.availShapes.length) {
      this.localParams.shape = this.faci.params.availShapes[0];
    }
    if (this.faci.params.stampRemove) {
      this.localParams.stampRemove = true;
    }
    if (this.faci.params.kanbanCapa && !this.faci.params.sendAuto) {
      this.updateSendAuto(true);
    }
  },
  methods: {
    moveToZone(prod, newZone) {
      if (!prod) {
        return;
      }
      this.isSlideRight = false;
      if (
        (prod.pos.zone !== "wipout" && newZone === "workdesk") ||
        newZone === "wipout"
      ) {
        this.isSlideRight = true;
      }
      let newMovesTab = [...prod.stats.moves];
      let timestamp = firebase.firestore.Timestamp.now();
      newMovesTab[newMovesTab.length - 1].leave_at = timestamp;
      let actionObj = {
        type: "unload",
        fid: this.faci.id,
        duration: this.faci.params.loadingDuration || 300,
        timestamp: firebase.firestore.Timestamp.now()
      };
      if (newZone !== "workdesk") {
        newMovesTab[newMovesTab.length - 1].actions.push(actionObj);
      }
      newMovesTab.push({
        fid: this.faci.id,
        zone: newZone,
        enter_at: timestamp,
        actions: newZone === "workdesk" ? [{ ...actionObj, type: "load" }] : []
      });
      this.$store.dispatch("updateProductsDocs", [
        {
          pid: prod.id,
          gid: this.currentGame.id,
          obj: {
            pos: { fid: this.faci.id, zone: newZone },
            [`stats.moves`]: newMovesTab
          }
        }
      ]);
      this.$refs["donestocklist"].resetSelection();
      this.$refs["wipstocklist"].resetSelection();
    },
    changeTool() {
      // Log the changeover in the product's actions :
      let newMovesTab = [...this.workdeskProduct.stats.moves];
      newMovesTab[newMovesTab.length - 1].actions.push({
        type: "changeover",
        fid: this.faci.id,
        duration: this.faci.params.changeoverDuration,
        timestamp: firebase.firestore.Timestamp.now()
      });
      this.$store.dispatch("updateProductsDocs", [
        {
          pid: this.workdeskProduct.id,
          gid: this.currentGame.id,
          obj: {
            [`stats.moves`]: newMovesTab
          }
        }
      ]);
      // Start the changeover animation :
      this.isChangeover = true;
      this.changeoverAnim.pourc = 0;
      anime({
        targets: this.changeoverAnim,
        pourc: 100,
        duration: this.faci.params.changeoverDuration,
        easing: "linear"
      }).finished.then(() => {
        this.isChangeover = false;
        this.changeoverAnim.pourc = 0;
      });
    },
    addTrace(obj) {
      if (!this.workdeskProduct) {
        return;
      }
      this.$store.dispatch("updateProductsDocs", [
        {
          pid: this.workdeskProduct.id,
          gid: this.currentGame.id,
          obj: {
            [`design.addTraces`]: firebase.firestore.FieldValue.arrayUnion(obj)
          }
        }
      ]);
    },
    removeTrace(obj) {
      if (!this.workdeskProduct) {
        return;
      }
      let remTab = [];
      this.workdeskProduct.design.addTraces.forEach(trace => {
        if (trace.opeId === obj.opeId) {
          remTab.push(trace);
        }
      });
      if (remTab.length) {
        this.$store.dispatch("updateProductsDocs", [
          {
            pid: this.workdeskProduct.id,
            gid: this.currentGame.id,
            obj: {
              [`design.addTraces`]: firebase.firestore.FieldValue.arrayRemove(
                ...remTab
              )
            }
          }
        ]);
      }
    },
    applyStatus({ opeId, status }) {
      let newOpeTab = [...this.workdeskProduct.operations];
      newOpeTab.forEach(op => {
        if (op.id === opeId) {
          op.status = status;
        }
      });
      let newMovesTab = [...this.workdeskProduct.stats.moves];
      newMovesTab[newMovesTab.length - 1].actions.push({
        type: "operation",
        fid: this.faci.id,
        opeid: opeId,
        status: status,
        timestamp: firebase.firestore.Timestamp.now()
      });
      this.$store.dispatch("updateProductsDocs", [
        {
          pid: this.workdeskProduct.id,
          gid: this.currentGame.id,
          obj: {
            operations: newOpeTab,
            [`stats.moves`]: newMovesTab
          }
        }
      ]);
    },
    updateSendAuto(bool) {
      this.$store.dispatch("updateGameDoc", [
        {
          obj: {
            [`facilities.${this.faci.id}.params.sendAuto`]: bool
          },
          gid: this.currentGame.id
        }
      ]);
    }
  },
  watch: {
    keyChange: {
      handler() {
        setTimeout(() => {
          if (
            this.faci.params.sendAuto &&
            !this.workdeskProduct &&
            this.nextProduct
          ) {
            this.moveToZone(this.nextProduct, "workdesk");
          }
        }, 2);
      },
      immediate: true
    }
  },
  data() {
    return {
      isSlideRight: true,
      doneProducts: { all: [], selected: [] },
      wipProducts: { all: [], selected: [] },
      isChangeover: false,
      changeoverAnim: { pourc: 0 },
      localParams: {}
    };
  }
};
</script>

<style lang="scss">
.painting-station-wrapper {
  .desk-product-wrapper {
    min-height: 200px;
    box-shadow: 0 0 20px 0px #eee inset;
    border: 1px solid #ccc;
    border-radius: 5px;
    .column svg {
      box-shadow: 0 0 20px 10px #ddd;
    }
  }
  .slide-fade-enter {
    transform: translateX(-400px);
    opacity: 0;
    &.is-left {
      transform: translateX(400px);
    }
  }
  .slide-fade-leave-to {
    transform: translateX(400px);
    opacity: 0;
    &.is-left {
      transform: translateX(-400px);
    }
  }
}
</style>
